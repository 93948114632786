import {
  Card,
  CardActionArea,
  Divider,
  Grid,
  Paper,
  SvgIcon,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { SessionManager } from "react-session.manager.sk";
import { RoleRoutes } from "../../Pages";
import { Stack } from "@mui/system";
import Summary from "../Components/common/Summary";
import Rescources from "../Components/common/Recources";
import { Link as LinkIcon } from "@mui/icons-material";

export default function DashBoard() {
  const { userInfo } = React.useContext(SessionManager);

  const filtered = RoleRoutes.filter((val) => {
    return userInfo?.pages?.includes(val.name);
  });

  const basic = ["Quotes", "Clients", "Orders", "RFQ"];
  const admin = [
    "Admin Quotes",
    "Admin Clients",
    "Companies",
    "Admin Orders",
    "Reports",
  ];
  const pos = [
    "Pos",
    "Cheques",
    "Store",
    "Suppliers",
    "Invoices",
    "Logistics",
    "Repairs",
    "Order Report",
    "Toners",
  ];

  return (
    <Stack
      direction={"column"}
      spacing={2}
      justifyContent={"space-around"}
      sx={{
        mt: 2,
        minHeight: "70vh",
      }}
    >
      {filtered.length === 0 ? (
        <Typography variant="h3" align="center">
          Welcome to TecToday
        </Typography>
      ) : (
        <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems={"center"}
            sx={{ mb: 2 }}
          >
            <SvgIcon color="inherit">
              <LinkIcon />
            </SvgIcon>
            <Typography variant="h5" color="inherit">
              Quick Links
            </Typography>
          </Stack>

          <QuickList allRoutes={filtered} nameList={basic} starter />

          <QuickList allRoutes={filtered} nameList={admin} />

          <QuickList allRoutes={filtered} nameList={pos} />
        </Paper>
      )}
      <Summary />
      <Rescources />
    </Stack>
  );
}

function QuickList({ allRoutes, nameList, starter = false }) {
  const filtered = allRoutes.filter((val) => {
    return nameList.includes(val.name);
  });

  if (filtered.length === 0) {
    return null;
  }

  return (
    <>
      {!starter && <Divider sx={{ mb: 2 }} />}
      <Grid container spacing={2} sx={{ justifyContent: "flex-start", mb: 2 }}>
        {filtered.map((val, index) => (
          <LinkCard key={index} name={val.name} url={val.url} icon={val.icon} />
        ))}
      </Grid>
    </>
  );
}

function LinkCard({ name, url, icon }) {
  return (
    <Grid item xs={12} md={4} lg={3}>
      <Card
        sx={{
          textAlign: "center",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <CardActionArea sx={{ p: 2 }} component={Link} to={url}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SvgIcon component={icon} />
            <Typography>{name}</Typography>
          </Stack>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
