import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Tooltip,
  IconButton,
  Paper,
  Collapse,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  Grid,
  Alert,
  CardActionArea,
  Divider,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { toast } from "react-toastify";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneIcon from "@mui/icons-material/Done";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { TransitionGroup } from "react-transition-group";

import { getStore } from "../../API/Cheques&Pos";
import { LinkOffRounded, LinkRounded, Store } from "@mui/icons-material";
import NumberFormat from "../../../Components/common/NumberFormater";

export default function AddItemForm({
  defaultPartNumber,
  defaultDescription,
  defaultPrice,
  defaultQuantity,
  onComplete,
  onlyStore,
  exchangeRates,
  setExchangeRates,
  poCurrency,
}) {
  const [partNumber, setPartNumber] = React.useState(defaultPartNumber),
    [section, setSection] = React.useState((onlyStore && "store") || "entry"),
    [description, setDescription] = React.useState(defaultDescription),
    [disabled, setDisabled] = React.useState(true),
    [price, setPrice] = React.useState(0),
    [itemList, setItemList] = React.useState([]),
    [isStore, setIsStore] = React.useState(onlyStore || false),
    [storeID, setStoreID] = React.useState(null),
    [quantity, setQuantity] = React.useState(defaultQuantity);
  const [loading, setLoading] = React.useState(true);

  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    const editValue = () => {
      setLoading(true);
      getStore({ searchKey: value })
        .then((res) => {
          setItemList(res);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Store items could not be retrieved due to an error");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const editField = setTimeout(editValue, 500);

    return () => {
      clearTimeout(editField);
    };
  }, [value]);

  React.useEffect(() => {
    if (section === "manual") {
      setDescription(defaultDescription);
      setPartNumber(defaultPartNumber);
      setQuantity(defaultQuantity);
      setPrice("0");
      return;
    }
    if (section === "entry") {
      setDescription("");
      setPartNumber("");
      setQuantity(0);
      setPrice(0);
    }
  }, [
    section,
    defaultPartNumber,
    defaultDescription,
    defaultPrice,
    defaultQuantity,
  ]);

  React.useEffect(() => {
    if (description && price && price > 0 && quantity && quantity > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [partNumber, price, quantity, description, isStore]);

  const handleSubmit = () => {
    const data = {
      partNumber,
      description,
      price,
      quantity,
      isStore,
      storeID,
    };

    onComplete(data);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack
        direction="column"
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        {section === "entry" && !onlyStore && (
          <ChoiceArea setSection={setSection} setIsStore={setIsStore} />
        )}
        {section === "manual" && !onlyStore && (
          <ManualArea
            partNumber={partNumber}
            setPartNumber={setPartNumber}
            description={description}
            setDescription={setDescription}
            price={price}
            setPrice={setPrice}
            quantity={quantity}
            setQuantity={setQuantity}
            setSection={setSection}
            setIsStore={setIsStore}
          />
        )}
        {(section === "store" || onlyStore) && (
          <StoreArea
            setPartNumber={setPartNumber}
            setDescription={setDescription}
            setPrice={setPrice}
            setQuantity={setQuantity}
            quantity={quantity}
            setSection={setSection}
            storeList={itemList}
            setIsStore={setIsStore}
            setStoreID={setStoreID}
            value={value}
            setValue={setValue}
            onlyStore={onlyStore}
            disabled={disabled}
            handleSubmit={handleSubmit}
            exchangeRates={exchangeRates}
            setExchangeRates={setExchangeRates}
            poCurrency={poCurrency}
            loading={loading}
          />
        )}
        {(section === "manual" || section === "store") && (
          <ActionArea
            handleSubmit={handleSubmit}
            disabled={disabled}
            onClose={() => {
              setIsStore(false);
              setSection("entry");
            }}
            onlyStore={onlyStore}
          />
        )}
      </Stack>
    </form>
  );
}

function ActionArea({ handleSubmit, disabled, onClose, onlyStore }) {
  return (
    <DialogActions>
      <LoadingButton
        startIcon={<AddIcon />}
        variant="contained"
        onClick={handleSubmit}
        disabled={disabled}
        type="submit"
      >
        Add Item
      </LoadingButton>
      {!onlyStore && (
        <Button onClick={onClose} color="warning" variant="outlined">
          Cancel
        </Button>
      )}
    </DialogActions>
  );
}

function ManualArea({
  partNumber,
  setPartNumber,
  description,
  setDescription,
  price,
  setPrice,
  quantity,
  setQuantity,
  setSection,
  setIsStore,
}) {
  const [linked, setLinked] = React.useState(false);

  const handelPartChange = (e) => {
    setPartNumber(e.target.value);
    if (linked) {
      setDescription(e.target.value);
    }
  };

  const handelDescriptionChange = (e) => {
    setDescription(e.target.value);
    if (linked) {
      setPartNumber(e.target.value);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tooltip title={`Go back`} sx={{ mb: 1 }}>
        <IconButton
          onClick={() => {
            setIsStore(false);
            setSection("entry");
          }}
        >
          <ArrowBackIcon color={"primary"} sx={{ fontSize: "27px" }} />
        </IconButton>
      </Tooltip>

      <Stack spacing={2} sx={{ mt: 2, px: 2 }}>
        <Stack direction="row">
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              ml: -3,
              mr: -1,
              ...(linked && {
                "&::before, &::after": {
                  borderColor: "info.main",
                },
              }),
            }}
          >
            <Tooltip title="Link partnumber and description">
              <IconButton
                color="info"
                size="small"
                onClick={() => setLinked((prev) => !prev)}
              >
                {linked ? <LinkOffRounded /> : <LinkRounded />}
              </IconButton>
            </Tooltip>
          </Divider>
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <TextField
              label="Part Number"
              value={partNumber}
              onChange={handelPartChange}
              fullWidth
            />
            <TextField
              label="Description"
              value={description}
              onChange={handelDescriptionChange}
              maxRows={5}
              fullWidth
              multiline
              required
            />
          </Stack>
        </Stack>
        <TextField
          label="Quantity"
          value={quantity}
          type="number"
          onWheel={(e) => e.target.blur()}
          inputProps={{ min: 1 }}
          required
          onChange={(e) => setQuantity(e.target.value)}
        />
        <TextField
          label="Price"
          value={price}
          type="number"
          onWheel={(e) => e.target.blur()}
          inputProps={{ min: 0 }}
          required
          onChange={(e) => setPrice(e.target.value)}
          onFocus={(e) => e.target.select()}
          autoFocus
        />
      </Stack>
    </Box>
  );
}

function ChoiceArea({ setSection, setIsStore }) {
  return (
    <Stack spacing={5} justifyContent={"center"}>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          setSection("manual");
          setIsStore(false);
        }}
      >
        Add Item
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          setSection("store");
          setIsStore(true);
        }}
        startIcon={<Store />}
      >
        Add item from store
      </Button>
    </Stack>
  );
}
function StoreArea({
  setSection,
  setPartNumber,
  setDescription,
  setPrice,
  setQuantity,
  quantity,
  storeList,
  setIsStore,
  setStoreID,
  value,
  setValue,
  onlyStore,
  disabled,
  handleSubmit,
  poCurrency,
  exchangeRates,
  loading,
  setExchangeRates,
}) {
  const [selected, setSelected] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [max, setMax] = React.useState(0);
  const [itemCurrency, setItemCurrency] = React.useState(poCurrency);
  const [rate, setRate] = React.useState(
    exchangeRates.KES.rate / exchangeRates.USD.rate
  );
  const [changeOpen, setChangeOpen] = React.useState(false);

  const [basePrice, setBasePrice] = React.useState(0);
  const [baseCurrency, setBaseCurrency] = React.useState(poCurrency);

  const convertCurrency = (cost, currency) => {
    const converted =
      cost *
      (exchangeRates[poCurrency].rate /
        exchangeRates[currency || poCurrency].rate);

    return converted;
  };

  const changeExchangeRate = () => {
    setExchangeRates((previous) => {
      previous.KES.rate = rate * previous.USD.rate;

      return { ...previous };
    });

    setPrice(convertCurrency(basePrice, baseCurrency));

    setChangeOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Set Quantity</DialogTitle>
        {poCurrency !== itemCurrency && (
          <Alert severity="warning" sx={{ borderRadius: 0 }}>
            Item will be converted to {poCurrency}
            <CardActionArea onClick={() => setChangeOpen(!changeOpen)}>
              @{" "}
              {NumberFormat(
                (exchangeRates.KES.rate / exchangeRates.USD.rate).toFixed(2)
              )}
              <Typography variant="caption" component={Box} sx={{ mb: 1 }}>
                (click me to change)
              </Typography>
              <Stack spacing={0.5} sx={{ mb: 1 }}>
                <Divider />
                <Divider />
              </Stack>
              <Typography>
                Unit Price:{" "}
                {NumberFormat(
                  convertCurrency(basePrice, baseCurrency).toFixed(2)
                )}
              </Typography>
            </CardActionArea>
            <Collapse in={changeOpen}>
              <Stack alignItems="center" direction="row" spacing={2}>
                <TextField
                  value={rate}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => setRate(e.target.value)}
                />
                <Button variant="contained" onClick={changeExchangeRate}>
                  Convert
                </Button>
              </Stack>
            </Collapse>
          </Alert>
        )}
        <DialogContent dividers>
          <TextField
            label="Quantity"
            value={quantity}
            type="number"
            onWheel={(e) => e.target.blur()}
            inputProps={{ min: 1, max }}
            required
            onChange={(e) => setQuantity(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              setIsStore(false);
              setSection("entry");
              handleSubmit();
            }}
            disabled={disabled}
          >
            Add item
          </Button>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => {
              setOpen(false);
            }}
          >
            {" "}
            close
          </Button>
        </DialogActions>
      </Dialog>
      {!onlyStore && (
        <Tooltip title={`Go back`} sx={{ mb: 1 }}>
          <IconButton
            onClick={() => {
              setIsStore(false);
              setSection("entry");
            }}
          >
            <ArrowBackIcon color={"primary"} sx={{ fontSize: "27px" }} />
          </IconButton>
        </Tooltip>
      )}

      <Stack spacing={2}>
        <TextField
          label="Search Store Items"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <Box>{loading && <CircularProgress size={25} />}</Box>
            ),
          }}
        />

        <Paper>
          <List>
            {!loading && storeList.length === 0 && (
              <Typography variant="subtitle1" align="center">
                No item found matching the search key
              </Typography>
            )}
            <TransitionGroup>
              {storeList.map((item) => (
                <Collapse key={item.id}>
                  <ListItem
                    key={item.id}
                    secondaryAction={
                      <Tooltip title="Add Store item">
                        <span>
                          <IconButton
                            edge="end"
                            aria-label="add"
                            onClick={() => {
                              setPartNumber(item.partNumber);
                              setDescription(item.description);
                              setQuantity(1);
                              setPrice(
                                convertCurrency(item.cost, item.currency)
                              );
                              setBasePrice(item.cost);
                              setBaseCurrency(item.currency);
                              setItemCurrency(item.currency);
                              setStoreID(item.id);
                              setSelected(item.id);
                              setOpen(true);
                              setMax(item.quantity);
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    }
                  >
                    <ListItemText
                      primary={
                        <Stack>
                          <Typography>
                            {item.description}{" "}
                            {item.id === selected && (
                              <DoneIcon sx={{ color: "lightgreen" }} />
                            )}
                          </Typography>
                          <Typography variant="caption">
                            {`${item.total}`}
                          </Typography>
                          <Typography variant="caption">
                            {`QTY ${item.quantity}`}
                          </Typography>
                          {item.reserveList.map((reserve) => (
                            <Grid container>
                              <Typography variant="caption">{`Reserved ${reserve.quantity} for ${reserve.name}`}</Typography>
                            </Grid>
                          ))}
                        </Stack>
                      }
                    />
                  </ListItem>
                </Collapse>
              ))}
            </TransitionGroup>
          </List>
        </Paper>
      </Stack>
    </>
  );
}
